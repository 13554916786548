import { useState } from "react";
import logo from "./businet-logo.png";

export default function SetupForm() {
    const [email, setEmail] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        const response = await fetch("http://127.0.0.1:5001/setup", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, api_key: apiKey }),
        });

        const data = await response.json();
        setMessage(data.message || data.error);
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100 px-6">
            <div className="p-8 max-w-lg bg-white shadow-2xl rounded-xl text-left ml-40 border border-gray-300">
                <div className="flex justify-center">
                    <img src={logo} alt="BusiNET Logo" className="w-40 mb-6" />
                </div>
                <h2 className="text-3xl font-bold mb-6 text-center">Configurazione Email Manager</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <label className="block font-medium text-gray-700">
                        Email:
                        <input 
                            type="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            className="w-full p-3 border rounded-lg mt-2 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                            required
                            placeholder="Inserisci la tua email"
                        />
                    </label>
                    <label className="block font-medium text-gray-700">
                        API Key:
                        <input 
                            type="password" 
                            value={apiKey} 
                            onChange={(e) => setApiKey(e.target.value)} 
                            className="w-full p-3 border rounded-lg mt-2 focus:ring-2 focus:ring-blue-400 focus:outline-none"
                            required
                            placeholder="Inserisci la tua API Key"
                        />
                    </label>
                    <button type="submit" className="w-full bg-blue-600 text-white font-semibold px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300">Salva</button>
                </form>
                {message && <p className="mt-6 text-center text-green-600 font-medium">{message}</p>}
                <div className="mt-8 flex justify-center items-center text-gray-500 text-sm">
                    <p>Powered by BusiNET</p>
                </div>
            </div>
        </div>
    );
}
